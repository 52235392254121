<template>
    <div class="container">
        <div class="header">
            <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
            <h1>{{ $t('buildAppTitle') }}</h1>
        </div>
        <p v-if="appsFromStore.length <= 13">{{ $t('buildAppSubtitle') }}</p>
        <p v-if="appsFromStore.length > 13">{{ $t('maxAppsMessage') }}</p>
        <div v-if="appsFromStore.length <= 13" class="frame">
            <div v-for="(row, index) in filteredRows" :key="index"
                :class="['frame-row', { 'double-row': selectedImages[index]?.size === 2 }, { 'no-row': row.hidden }]">
                <img v-if="selectedImages[index]" :src="selectedImages[index].src" class="frame-image"
                    :class="{ 'double-image': selectedImages[index]?.size === 2 }" alt="Selected Image">
                <button v-if="selectedImages[index]" class="delete-button" @click="removeImage(index)">x</button>
                <button v-else-if="!row.hidden" class="plus-button" @click="openPopup(index)">+</button>
            </div>
        </div>
        <button v-if="appsFromStore.length <= 13" class="button" @click="continueButton">{{ $t('continueButton')
            }}</button>

        <div v-if="showPopup" class="popup-overlay">
            <div class="popup">
                <div class="header">
                    <button class="back-button" @click="closePopup"><i class="fas fa-arrow-left"></i></button>
                    <h1>{{ $t('popupTitle') }}</h1>
                </div>
                <p>{{ $t('popupSubtitle') }}</p>
                <div class="card-container">
                    <div v-for="(card, index) in filteredCards" :key="index" class="card-wrapper"
                        :class="{ 'double-card-wrapper': card.size === 2 }" @click="!card.hidden && selectCard(index)">
                        <div class="card-background">
                            <p class="card-title">{{ card.name }}</p>
                            <div class="card" :class="{ 'double-card': card.size === 2 }">
                                <img :src="getCardImage(card)" class="card-image" alt="Card Image">
                                <!-- Overlay for "Coming Soon" if hidden is true -->
                                <div v-if="card.hidden" class="coming-soon-overlay">Coming Soon</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPopup: false,
            selectedRow: null,
            selectedImages: [null, null, null],
            cards: [],
        };
    },
    computed: {
        appsFromStore() {
            // Assuming you are storing apps in the Vuex store
            return this.$store.state.authUser.apps;
        },
        filteredRows() {
            let rows = this.selectedImages.map((image, index) => ({
                index,
                image,
                hidden: this.isRowHidden(index),
            }))//.filter(row => !row.hidden);
            return rows;
        },
        filteredCards() {
            // Filter out size 2 cards if the selected row is the last row or the next row is occupied
            let filtered = this.cards;
            if (this.selectedRow === 2 || (this.selectedRow < 2 && this.selectedImages[this.selectedRow + 1])) {
                filtered = filtered.filter(card => card.size === 1);
            }

            // Sort by index (number) ascending, attach cards with no index at the end
            filtered.sort((a, b) => {
                if (a.index !== undefined && b.index !== undefined) {
                    return a.index - b.index;
                } else if (a.index !== undefined) {
                    return -1;
                } else if (b.index !== undefined) {
                    return 1;
                } else {
                    return 0;
                }
            });

            return filtered;
        },
    },
    created() {
        this.fetchCards();  // Fetch the cards when the component is created
    },
    methods: {
        async fetchCards() {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/getCards?clockId=${this.cosmoId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch cards');
                }
                const data = await response.json();
                // console.log(data)
                this.cards = data;  // Store the fetched cards in the cards array
            } catch (error) {
                console.error(error);
                alert(error.message);  // Show error message
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        openPopup(row) {
            // console.log("OPEN " + row)
            this.selectedRow = row;
            this.showPopup = true;
            document.body.style.overflow = 'hidden'; // Prevent scrolling of the background
        },
        closePopup() {
            this.showPopup = false;
            document.body.style.overflow = ''; // Restore scrolling of the background
        },
        getCardImage(card) {
            return `/images/displayCards/${card.imagePath}`;
        },
        selectCard(index) {
            const card = this.filteredCards[index];
            const src = this.getCardImage(card);
            const size = card.size;
            const id = card.id;
            this.selectedImages[this.selectedRow] = { id, src, size };

            // If the card size is 2, also mark the next row as occupied by the same card
            // if (size === 2 && this.selectedRow < 2) {
            //     this.selectedImages[this.selectedRow + 1] = { src, size };
            // }

            this.closePopup();
        },
        removeImage(index) {
            //const size = this.selectedImages[index]?.size || 1;
            this.selectedImages[index] = null;
            // If the image size is 2, also clear the next row
            // if (size === 2 && index < 2) {
            //     this.selectedImages[index + 1] = null;
            // }
        },
        isRowHidden(index) {
            // If the previous row has an image with size 2, hide this row
            return index > 0 && this.selectedImages[index - 1]?.size === 2;
        },
        continueButton() {
            const selectedCardPositions = [];

            this.selectedImages.forEach((image, index) => {
                if (image) {
                    const card = this.cards.find(card => image.id === card.id);
                    if (card) {
                        selectedCardPositions.push({
                            position: index,
                            card: card,
                        });
                    } else {
                        // console.log(`Position: ${index}, Card not found.`);
                    }
                }
            });

            // Only proceed if at least one card is selected
            if (selectedCardPositions.length > 0) {
                this.$store.dispatch('updateSelectedCardPositions', selectedCardPositions);
                this.$router.push({ name: 'CosmoBuildAppSettings' });
            }
        }
    }
};
</script>

<style scoped>
.container p {
    margin-bottom: 20px;
}

.frame {
    width: 280px;
    height: 480px;
    background: white;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;
}

.frame-row {
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.double-row {
    height: 320px;
}

.no-row {
    height: 0px;
}

.coming-soon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    border-radius: 20px;
    z-index: 1; /* Ensure it's above the card image */
}

.frame-image {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.double-image {
    height: 320px;
}

.delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ffffff00;
    color: #333;
    border: 1px solid #333;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
}

.divider {
    width: 100%;
    height: 1px;
    border-top: 1px dashed #ffffff30;
    margin: 0;
}

.plus-button {
    font-size: 24px;
    background: #ffffff00;
    color: #333;
    border: 1px solid #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
}


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #262626;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup {
    width: 90%;
    max-height: 90%;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
}

.popup-title {
    flex-grow: 1;
    text-align: center;
}


.card-container {
    width: 100%;
    height: calc(100% - 100px);
    /* Adjust height based on header height */
    overflow-y: auto;
    /* padding: 20px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    /* Add space between cards */
}

.double-card-wrapper {
    margin-bottom: 20px;
    /* Ensure there is more space for double cards */
}

.card-background {
    background: #333;
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card {
    background: #ffffff;
    border-radius: 20px;
    width: 280px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
}

.double-card {
    height: 320px;
}

.card-image {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}


.app-icon {
    font-size: 30px;
    margin-bottom: 5px;
}

.card h3 {
    margin: 0;
    color: #fff;
}

.card-title {
    margin-bottom: 10px !important;
    margin-top: 0px !important;
    color: #fff;
    text-align: center;
}
</style>
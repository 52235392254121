<template>
    <div v-if="isImageLoaded">
        <div class="container" v-if="!hasEmail">
            <div class="header">
                <h1>{{ $t('cosmoClockTitle') }}</h1>
            </div>
            <form @submit.prevent="setEmail">
                <p class="subtitle">{{ $t('pleaseEnterEmail') }}</p>
                <input v-model="email" type="email" placeholder="Email" required />
                <button type="submit" class="button">{{ $t('Submit') }}</button>
            </form>
        </div>

        <div class="container" v-else>
            <div class="header">
                <img v-if="isV3Clock" :src="getLogoImage()" alt="COSMO II" class="logo-image" />
                <h1 v-if="!isV3Clock">{{ $t('cosmoClockTitle') }}</h1>
            </div>
            <p class="subtitle">{{ $t('welcomeDashboard', { clockName: clockName }) }}</p>

            <div class="app-cards">
                <!-- Conditional rendering based on clock version -->
                <template v-if="isV3Clock">
                    <!-- V3 Clock Menu Cards -->
                    <div class="card" @click="showApps">
                        <i class="fas fa-th-large card-icon"></i>
                        <h3>{{ $t('apps') }}</h3>
                    </div>
                    <div class="card" @click="showAlarm">
                        <i class="fas fa-bell card-icon"></i>
                        <h3>{{ $t('alarm') }}</h3>
                    </div>
                    <div class="card" @click="showSettings">
                        <i class="fas fa-cog card-icon"></i>
                        <h3>{{ $t('settings') }}</h3>
                    </div>
                    <div class="card" @click="showShop">
                        <i class="fas fa-shopping-cart card-icon"></i>
                        <h3>{{ $t('shop') }}</h3>
                    </div>
                    <div class="card" @click="showSetup">
                        <i class="fas fa-info-circle card-icon"></i>
                        <h3>{{ $t('setup') }}</h3>
                    </div>
                    <div class="card" @click="logout">
                        <i class="fas fa-sign-out-alt card-icon"></i>
                        <h3>{{ $t('logout') }}</h3>
                    </div>
                </template>
                <template v-else>
                    <!-- Existing Menu Cards -->
                    <div class="card" @click="showApps">
                        <i class="fas fa-th-large card-icon"></i>
                        <h3>{{ $t('apps') }}</h3>
                    </div>
                    <div class="card" @click="showSettings">
                        <i class="fas fa-cog card-icon"></i>
                        <h3>{{ $t('settings') }}</h3>
                    </div>
                    <div class="card" @click="showSetup">
                        <i class="fas fa-info-circle card-icon"></i>
                        <h3>{{ $t('setup') }}</h3>
                    </div>
                    <div class="card" @click="logout">
                        <i class="fas fa-sign-out-alt card-icon"></i>
                        <h3>{{ $t('logout') }}</h3>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>




<script>
export default {
    data() {
        return {
            logoSrc: '/images/logoPNG.png',
            isLoading: false,
            email: '',
            isImageLoaded: false,
        };
    },
    mounted() {
        this.preloadImage(this.logoSrc);
    },
    computed: {
        isV3Clock() {
            return this.clockId.startsWith("V3")
        },
        clockName() {
            const name = this.$store.state.authUser ? this.$store.state.authUser.name : 'Guest';
            return name.charAt(0).toUpperCase() + name.slice(1);
        },
        hasEmail() {
            return this.$store.state.authUser && this.$store.state.authUser.email;
        },
        clockId() {
            return this.$store.state.authUser.clockId; // Adjust this based on how you retrieve the clockId
        },
    },
    methods: {
        preloadImage(src) {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                this.isImageLoaded = true;
            };
        },
        getLogoImage() {
            return `/images/logoPNG.png`; // Adjust this to match the actual path to your logo
        },
        showApps() {
            this.$router.push({ name: 'CosmoApps' });
        },
        showSettings() {
            if (this.isV3Clock) {
                this.$router.push({ name: 'CosmoNewSettings' });
            } else {
                this.$router.push({ name: 'CosmoSettings' });
            }
        },
        showSetup() {
            this.$router.push({ name: 'CosmoSetup' });
        },
        showAlarm() {
            this.$router.push({ name: 'CosmoAlarms' });
        },
        showHelp() {
            this.$router.push({ name: 'CosmoHelp' });
        },
        showShop() {
            window.location.href = 'https://thecosmoclock.com/';
        },
        logout() {
            this.$store.commit('clearAuthUser');
            this.$router.push('/login');
        },
        refreshData() {
            this.isLoading = true;
            //const identifier = this.$store.state.authUser.name;
            // console.log(identifier)

            // Uncomment the following lines to make the actual request
            /*
            fetch(`${process.env.VUE_APP_API_BASE_URL}/login?identifier=${identifier}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to refresh data. Please try again.');
                    }
                    return response.json();
                })
                .then(data => {
                    // console.log("Data refreshed successfully");
                    this.$store.dispatch('authenticateUser', data);
                })
                .catch(error => {
                    console.error("Error refreshing data:", error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
            */

            // 5-second delay
            setTimeout(() => {
                this.isLoading = false;
            }, 3000);
        },
        async setEmail() {
            const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/setEmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    clockName: this.$store.state.authUser.name,
                    email: this.email
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to save settings. Please try again.');
            }

            // Optionally, handle the response data
            //const data = await response.json();
            // console.log(data)
            this.$store.commit('setAuthUserEmail', this.email);
        }
    },
};

</script>

<style scoped>
.container {
    text-align: center;
    padding: 20px;
}

.header h1 {
    margin-bottom: 20px;
    font-size: 40px;
}

.subtitle {
    margin-bottom: 10px;
}

input[type="email"] {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid white;
    border-radius: 20px;
    background-color: transparent;
    color: white;
    font-size: 16px;
}

input[type="email"]::placeholder {
    font-size: 16px;
}

.logo-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}

.app-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
}

.card {
    background: #333;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: calc(50% - 10px);
    max-width: 400px;
    box-sizing: border-box;
    transition: transform 0.3s ease;
}

.card-icon {
    font-size: 30px;
    margin-bottom: 10px;
}

.card h3 {
    margin: 0;
    text-align: center;
}

.card:hover {
    transform: scale(1.05);
}
</style>
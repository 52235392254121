<template>
    <div class="container">
        <template v-if="!saveSuccess">
            <div class="title-container">
                <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
                <h1>{{ $t('appSettingsTitle') }}</h1>
            </div>
            <div class="app-cards">
                <div class="card" v-for="(group, groupIndex) in settingsGroups" :key="groupIndex">
                    <h3>{{ translateAndCapitalize(group.title) }}</h3>
                    <div class="settings-wrapper">
                        <div class="setting" v-for="(setting, index) in group.settings" :key="index">
                            <div v-if="setting.type === 'dropdown'">
                                <label :for="setting.name">{{ translateAndCapitalize(setting.label) }}</label>
                                <select :id="setting.name" v-model="setting.current">
                                    <option v-for="option in setting.options" :value="option.value" :key="option.value">
                                        {{ translateAndCapitalize(option.label) }}
                                    </option>
                                </select>
                            </div>
                            <!-- Additional setting types can be handled here -->
                        </div>
                    </div>
                </div>
                <div v-if="appIsCustom" class="card">
    <h3>{{ translateAndCapitalize('deleteAppTitle') }}</h3>
    <div class="settings-wrapper">
        <!-- Conditional rendering for delete button or confirmation -->
        <div v-if="!showDeleteConfirmation">
            <button class="button delete-button" @click="confirmDeleteApp">
                {{ $t('deleteAppButton') }}
            </button>
        </div>
        <div v-else>
            <p>{{ $t('confirmDeleteAppMessage', { appName: appName }) }}</p>
            <button class="button confirm-button" @click="deleteApp">{{ $t('confirmDeleteButton') }}</button>
            <button class="button cancel-button" @click="cancelDelete">{{ $t('cancelButton') }}</button>
        </div>
    </div>
</div>
            </div>

            <!-- <button v-if="appIsCustom" class="button delete-button" @click="confirmDeleteApp">
                {{ $t('deleteAppButton') }}
            </button>

            <div v-if="showDeleteConfirmation" class="delete-confirmation">
                <p>{{ $t('confirmDeleteAppMessage', { appName: appName }) }}</p>
                <button class="button confirm-button" @click="deleteApp">{{ $t('confirmDeleteButton') }}</button>
                <button class="button cancel-button" @click="cancelDelete">{{ $t('cancelButton') }}</button>
            </div> -->

            <button class="button button-save" :disabled="isSaving" @click="saveSettings">
                {{ isSaving ? $t('savingButton') : $t('saveButton') }}
            </button>
        </template>
        <template v-else>
            <h1>{{ $t('settingsSavedTitle') }}</h1>
            <p>
                {{
                    $store.state.authUser.clockId && $store.state.authUser.clockId.startsWith('V3')
                        ? $t('settingsSavedDescriptionV3')
                        : $t('settingsSavedDescription')
                }}
            </p>
            <button class="button" @click="goHome">{{ $t('homeButton') }}</button>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isSaving: false,
            saveSuccess: false,
            settingsGroups: [], // Now using settingsGroups instead of settings
            appName: '',
            appIsCustom: false,
            showDeleteConfirmation: false,
        };
    },
    created() {
        const appName = this.$route.query.name;
        this.appName = appName;
        const app = this.$store.state.authUser.apps.find(app => app.name === appName);
        this.appIsCustom = app?.customApp || false;
        this.initializeSettings(appName);
    },
    methods: {
        confirmDeleteApp() {
            this.showDeleteConfirmation = true;
        },
        cancelDelete() {
            this.showDeleteConfirmation = false;
        },
        async deleteApp() {
            try {
                const app = this.$store.state.authUser.apps.find(app => app.name === this.appName);
                if (!app) {
                    throw new Error('App not found');
                }

                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/deleteCustomApp`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        clockId: this.$store.state.authUser.clockId,
                        appId: app.id,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to delete app. Please try again.');
                }

                await this.connect();
                this.$router.push({ name: 'CosmoHome' });
            } catch (error) {
                console.error('Error deleting app:', error);
                alert(error.message);
            }
        },
        translateAndCapitalize(key) {
            let translation = this.$t(key);

            if (translation === key) {
                translation = key;
            }

            return translation
                .toLowerCase()
                .replace(/(?:^|\s|-)\S/g, a => a.toUpperCase());
        },
        initializeSettings(appName) {
            const app = this.$store.state.authUser.apps.find(app => app.name === appName);
            if (!app) {
                console.error('App not found');
                return;
            }

            const userAppSettings = this.$store.state.authUser.appSettings?.find(setting => setting.appId === app.id);

            let intervalSettings = [];
            let soundSettings = [];
            let displayAreaSettings = [];

            // Update Interval Settings
            if (app.updateInterval !== undefined && app.availableUpdateIntervals) {
                let updateIntervalCurrent = app.updateInterval.toString();
                if (userAppSettings && userAppSettings.updateInterval !== undefined) {
                    updateIntervalCurrent = userAppSettings.updateInterval.toString();
                }
                intervalSettings.push({
                    type: 'dropdown',
                    name: 'updateInterval',
                    label: 'Update Interval',
                    current: updateIntervalCurrent,
                    options: app.availableUpdateIntervals.map(interval => ({
                        label: interval + ' minutes',
                        value: interval.toString()
                    }))
                });
            }

            // Sound Settings
            soundSettings.push(
                {
                    type: 'dropdown',
                    name: 'audioMinutes',
                    label: 'Play Sound Every',
                    current: userAppSettings?.audioMinutes?.toString() || '-1',
                    options: [
                        { label: 'Never', value: '-1' },
                        { label: '15 minutes', value: '15' },
                        { label: '30 minutes', value: '30' },
                        { label: '1 hour', value: '60' },
                    ]
                },
                {
                    type: 'dropdown',
                    name: 'audioHoursFrom',
                    label: 'From Hour',
                    current: userAppSettings?.audioHours?.[0]?.toString() || '0',
                    options: Array.from({ length: 24 }, (_, i) => ({
                        label: `${i}:00`,
                        value: i.toString(),
                    })),
                    hidden: userAppSettings?.audioMinutes?.toString() === '-1'
                },
                {
                    type: 'dropdown',
                    name: 'audioHoursTo',
                    label: 'To Hour',
                    current: userAppSettings?.audioHours?.[userAppSettings.audioHours.length - 1]?.toString() || '23',
                    options: Array.from({ length: 24 }, (_, i) => ({
                        label: `${i}:00`,
                        value: i.toString(),
                    })),
                    hidden: userAppSettings?.audioMinutes?.toString() === '-1'
                },
                {
                    type: 'dropdown',
                    name: 'audioName',
                    label: 'Sound',
                    current: userAppSettings?.audioName || 'churchbell',
                    options: [
                        { label: 'churchbell', value: 'churchbell' },
                        { label: 'bowl', value: 'bowl' },
                        { label: 'cuckoo', value: 'cuckoo' },
                        { label: 'rooster', value: 'rooster' },
                        { label: 'futuristic', value: 'futuristic' }
                    ],
                    hidden: userAppSettings?.audioMinutes?.toString() === '-1'
                },
                {
                    type: 'dropdown',
                    name: 'audioGain',
                    label: 'Sound Volume',
                    current: userAppSettings?.audioGain || '50',
                    options: Array.from({ length: 10 }, (_, i) => ({
                        label: `${(i + 1) * 10}%`,
                        value: `${(i + 1) * 10}`,
                    })),
                }
            );

            // Display Area Settings
            const seenSettings = new Map();
            app.displayAreas.forEach((area) => {
                if (area.selectedVariableName && area.selectedVariable && area.availableVariables) {
                    const key = `${area.position}-${area.selectedVariableName}-${JSON.stringify(area.availableVariables)}`;

                    if (!seenSettings.has(key)) {
                        let currentVariable = area.selectedVariable;
                        const userAreaSetting = userAppSettings?.displayAreas?.find(userArea => userArea.selectedVariableName === area.selectedVariableName && userArea.position === area.position);
                        if (userAreaSetting && userAreaSetting.selectedVariable !== undefined) {
                            currentVariable = userAreaSetting.selectedVariable;
                        }

                        displayAreaSettings.push({
                            type: 'dropdown',
                            name: area.selectedVariableName,
                            label: area.selectedVariableName,
                            current: currentVariable,
                            options: area.availableVariables.map(variable => ({
                                label: variable,
                                value: variable
                            })),
                            position: area.position || 0
                        });

                        seenSettings.set(key, true);
                    }
                }
            });

            // Set the settings groups
            this.settingsGroups = [];
            if (intervalSettings.length > 0) {
                this.settingsGroups.push({ title: 'Update Interval', settings: intervalSettings });
            }
            if (soundSettings.length > 0) {
                this.settingsGroups.push({ title: 'Sound Settings', settings: soundSettings });
            }
            if (displayAreaSettings.length > 0) {
                this.settingsGroups.push({ title: 'App Settings', settings: displayAreaSettings });
            }
        },
        capitalizeWords(label) {
            return label.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
        },
        goBack() {
            this.$router.go(-1);
        },
        async connect() {
            const clockName = this.$store.state.authUser.name;
            this.isLoading = true;
            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/login?identifier=${clockName}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to connect. Please try again.');
                }
                const data = await response.json();
                this.connectedCosmo = data;
                this.$store.dispatch('authenticateUser', data);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading = false;
            }
        },
        async saveSettings() {
            this.isSaving = true;

            try {
                const app = this.$store.state.authUser.apps.find(app => app.name === this.appName);
                if (!app) {
                    throw new Error('App not found in store');
                }

                const updatedSettings = [];
                const soundSettings = {};

                // Iterate over each setting in settingsGroups
                this.settingsGroups.forEach(group => {
                    group.settings.forEach(setting => {
                        if (setting.type === 'dropdown') {
                            // Handle sound settings
                            if (setting.name === 'audioMinutes') {
                                soundSettings.audioMinutes = setting.current;
                            } else if (setting.name === 'audioHoursFrom') {
                                soundSettings.audioHoursFrom = setting.current;
                            } else if (setting.name === 'audioHoursTo') {
                                soundSettings.audioHoursTo = setting.current;
                            } else if (setting.name === 'audioName') {
                                soundSettings.audioName = setting.current;
                            } else if (setting.name === 'audioGain') {
                                soundSettings.audioGain = setting.current;
                            } else if (setting.name === 'updateInterval') {
                                updatedSettings.push({
                                    name: 'updateInterval',
                                    current: setting.current,
                                    type: setting.type,
                                });
                            } else {
                                // For display area settings
                                app.displayAreas.forEach((area) => {
                                    if (area.position === setting.position && area.selectedVariableName === setting.name) {
                                        updatedSettings.push({
                                            name: area.selectedVariableName,
                                            current: setting.current,
                                            type: setting.type,
                                            position: area.position
                                        });
                                    }
                                });
                            }
                        }
                    });
                });

                // Prepare the request body
                const requestBody = {
                    clockName: this.$store.state.authUser.name,
                    appId: app.id,
                    settings: updatedSettings,
                    customApp: app.customApp || false,
                    soundSettings: soundSettings
                };

                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/saveAppSettings`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (!response.ok) {
                    throw new Error('Failed to save settings. Please try again.');
                }

                const data = await response.json();

                this.$store.commit('updateAppSettings', data.appSettings);
                await this.connect();
                this.saveSuccess = true;
            } catch (error) {
                console.error('Error saving settings:', error);
                alert(error.message);
            } finally {
                this.isSaving = false;
            }
        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
        navigateTo(destination) {
            this.$router.push(destination);
        },
    },
};
</script>

<style scoped>
.container p {
    margin-bottom: 0px;
}

/* Card styles */
.app-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.card {
    background: #333;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    transition: transform 0.3s ease;
}

/* Settings styles */
.settings-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.setting {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.setting>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Creates space between the label and the toggle */
}


.setting select {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    -webkit-appearance: none;
    appearance: none;
}

.setting option {
    background-color: #262626;
    color: white;
}

/* Button styles */
.button {
    margin-top: 10px;
}
.card .button {
    margin-top: 10px;
    margin-bottom: 20px;
}

.card .delete-button {
    background-color: #ff4d4d00; /* Red background for delete button */
    color: white;
}

.card .confirm-button {
    background-color: #ff4d4d00; /* Red background for confirm button */
    color: white;
    margin-right: 10px;
}

.card .cancel-button {
    background-color: #ccc; /* Gray background for cancel button */
    color: black;
}
.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button-save {
    margin-top:50px
}

/* Delete confirmation styles */
.delete-confirmation {
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-top: 20px;
}

.confirm-button {
    margin-right: 10px;
}

.delete-button {
    margin-top: 30px;
}

/* Title container styles */
.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
}
</style>

<template>
  <div class="container">
    <div class="header">
      <button v-if="!selectedApp" class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
      <h1 v-if="!selectedApp">{{ $t('appSelectionTitle') }}</h1>
      <h1 v-if="selectedApp">{{ $t('appConfirmedTitle') }}</h1>
    </div>
    <p v-if="!selectedApp">{{ $t('selectAppDisplay') }}</p>

    <!-- Check if clockId starts with V3 -->
    <div v-if="isV3Clock && !selectedApp" class="v3-app-grid">
      <div v-for="(app, index) in appsFromStore" :key="index" class="v3-app-container" @click="selectApp(app)">
        <div class="v3-app-card">
          <p>{{ app.name }}</p>
          <div class="frame-container">
            <div class="frame small-frame">
              <div v-for="position in [0, 1, 2]" :key="position" :class="[
                'frame-row',
                { 'double-row': getCardByPosition(app, position)?.size === 2 },
                { 'no-row': position > 0 && getCardByPosition(app, position - 1)?.size === 2 }
              ]">
                <!-- Check if the card exists for this position -->
                <img
                  v-if="!getCardByPosition(app, position) && position > 0 && getCardByPosition(app, position - 1)?.size !== 2"
                  :src="getCardImage({ imagePath: 'empty.png' })" class="frame-image small-frame-image"
                  alt="Placeholder Image">

                <img v-else-if="getCardByPosition(app, position)" :src="getCardImage(getCardByPosition(app, position))"
                  class="frame-image small-frame-image"
                  :class="{ 'double-image': getCardByPosition(app, position)?.size === 2 }" alt="Card Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div v-else-if="!selectedApp" class="app-grid">
      <div class="app-card" v-for="(app, index) in appsFromStore" :key="index" @click="selectApp(app)">
        <i :class="`fas ${app.icon}`" class="app-icon"></i>
        <h3>{{ app.name }}</h3>
      </div>
    </div>

    <div v-else class="confirmation">
      <p>
                {{
                    $store.state.authUser.clockId && $store.state.authUser.clockId.startsWith('V3')
                        ? $t('settingsSavedDescriptionV3')
                        : $t('appWillNowBeDisplayed')
                }}
            </p>
      <button class="button" @click="goHome">{{ $t('homeButton') }}</button>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      selectedApp: null
    };
  },
  computed: {
    isV3Clock() {
      return this.$store.state.authUser.clockId.startsWith("V3");
    },
    // Access apps from the Vuex store
    appsFromStore() {
      const appTranslationKeys = {
        1: 'appNameClock',
        2: 'appNameTempIn',
        3: 'appNameTempOut',
        4: 'appNameTimeMoon',
        5: 'appNameTimeHoro',
        6: 'appNameTimeStocks',
        7: 'appNameWeather',
        8: 'appNameForecast',
        9: 'appNameMoon',
        10: 'appNameHoroscope',
        11: 'appNameStocks',
        // Add more mappings as needed
      };
      if (this.isV3Clock) {
        // For V3 clock, retrieve apps with cards
        console.log(this.$store.state.authUser.apps)
        return this.$store.state.authUser.apps.map(app => {
          return {
            ...app,
            cards: app.customApp
              ? this.$store.state.authUser.customApps.find(customApp => customApp.clockJson.id === app.id)?.cards
              : this.$store.state.authUser.defaultApps.find(defaultApp => defaultApp.clockJson.id === app.id)?.cards,
            name: appTranslationKeys[app.id] ? this.$t(appTranslationKeys[app.id]) : app.name || `App ${app.id}`,
          };
        });
      } else {
        // For non-V3 clock, return apps normally
        return this.$store.state.authUser.apps.map(app => {
          return {
            ...app,
            name: appTranslationKeys[app.id] ? this.$t(appTranslationKeys[app.id]) : app.name || `App ${app.id}`,
          };
        });
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    selectApp(app) {

      // console.log(app)
      // Prepare the data for the POST request to reflect the expected array of appIds
      const postData = {
        clockName: this.$store.state.authUser.name,
        appIds: [app.id], // Changed to an array of appIds
        updateInterval: app.updateInterval
      };

      // Updated URL of your endpoint to /displayApps
      const url = `${process.env.VUE_APP_API_BASE_URL}/displayApps`;

      // Make the HTTP POST request
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          data
          // console.log("App display settings updated successfully");
          // Proceed to confirm the app selection
          this.selectedApp = app;
          // Optionally, navigate to home or show a success message
          // this.goHome();
        })
        .catch(error => {
          console.error('Error updating app display settings:', error);
          // Handle errors, e.g., show an error message
        });
    },
    getCardImage(card) {
      return `/images/displayCards/${card.imagePath}`;
    },
    getCardByPosition(app, position) {
      return app.cards.find(card => card.position === position);
    },
    goHome() {
      this.$router.push({ name: 'CosmoHome' });
    }
  }
};
</script>


<style scoped>
.v3-app-card {
  background: #333;
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
}

.v3-app-container p {
  margin-bottom: 10px;
  margin-top: 0px;
  text-align: center;
  color: white;
}

.v3-app-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin: 0 auto;
}

.v3-app-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin: 0 auto;
}

.v3-app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.frame-container {
  width: 100%;
  max-width: 200px;
  /* Limit the maximum width */
  height: 0;
  padding-bottom: calc((480 / 280) * 100%);
  /* Maintain aspect ratio by padding */
  position: relative;
}

.frame.small-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.frame-row {
  width: 100%;
  height: 116px;
  /* Adjusted height to fit smaller frame */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.double-row {
  height: 232px;
  /* Adjusted for double-sized cards */
}

.no-row {
  height: 0px;
}

.frame-image.small-frame-image {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.double-image {
  height: 232px;
  /* Adjusted for double-sized images */
}

.confirmation p {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 20px;
}

.app-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  /* Center the grid */
}

.app-card {
  background: #333;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.app-icon {
  font-size: 30px;
  margin-bottom: 5px;
}

.confirmation {
  text-align: center;
}

.app-card h3 {
  text-align: center;
  margin: 10px 0 0;
  /* Adjust top margin to separate from icon */
}

.button {
  margin-top: 10px;
}
</style>
<template>
    <div class="container">
        <div class="title-container">
            <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
            <h1>{{ $t('setupTitle') }}</h1>
        </div>
        <div class="language-buttons">
            <button class="button" @click="changeLanguage('en')">EN</button>
            <button class="button" @click="changeLanguage('de')">DE</button>
            <button class="button" @click="changeLanguage('it')">IT</button>
            <button class="button" @click="changeLanguage('fr')">FR</button>
        </div>
        <div class="setup-content">
            <h2>{{ $t('quickStartGuide') }}</h2>
            <p>{{ $t('welcomeMessage') }}</p>

            <div class="card">
                <p>{{ $t('step1') }}</p>
            </div>
            <div class="card">
                <p>{{ $t('step2') }}</p>
            </div>
            <div class="card">
                <p>{{ $t('step3') }}</p>
            </div>
            <div class="card">
                <p>{{ $t('step4') }}</p>
            </div>
            <div class="card">
                <p v-html="$t('step5', { webAppLink: '<a href=\'' + $t('webAppURL') + '\' target=\'_blank\' style=\'color: white;\'>' + $t('webAppLinkText') + '</a>' })"></p>
            </div>
            <div class="card">
                <p>{{ $t('step6') }}</p>
            </div>
            <div class="card">
                <p>{{ $t('step7') }}</p>
            </div>

            <h2>{{ $t('forgotNameOrID') }}</h2>
            <div class="card">
                <p>{{ $t('forgotInstructions') }}</p>
            </div>

            <h2>{{ $t('needHelp') }}</h2>
            <div class="card">
                <p>{{ $t('helpMessage') }} <a :href="'mailto:phis.studio@gmail.com'">phis.studio@gmail.com</a>.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        mailtoUrl() {
            return `mailto:${this.$t('contactEmail')}`;
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1); // Go back to the previous page
        },
        changeLanguage(lang) {
            this.$i18n.locale = lang; // Change the language
        }
    },
};
</script>

<style scoped>
.setup-content a {
    color: white; /* Set link color to white */
    text-decoration: none; /* Optional: removes underline from links */
    font-weight: bold;
}

/* Add hover effect for better user experience */
.setup-content a:hover {
    color: #ccc; /* Change link color on hover */
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.language-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.language-buttons button {
    background-color: #333;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 20px;
}

.language-button.active {
    background-color: #555;
}

.language-buttons button:hover {
    background-color: #ddd; /* Change button background on hover */
}

.setup-content h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.setup-content p {
    margin-bottom: 15px;
    padding: 0 20px;
}

.container h1 {
    margin: 0;
    padding: 0;
}

.container p {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 20px;
}

.button {
    margin-top: 20px;
    width: auto;
}

.card {
    background: #333;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    color: white;
}
</style>

<template>
    <div class="container">
        <template v-if="!saveSuccess">
            <div class="title-container">
                <button class="back-button" @click="goBack"><i class="fas fa-arrow-left"></i></button>
                <h1>{{ $t('createAppSettingsTitle') }}</h1>
            </div>
            <p>{{ $t('customizeCreateAppSettings', { appName: appName }) }}</p>
            <div class="settings-wrapper">
                <div v-for="(group, groupIndex) in settings" :key="groupIndex" class="settings-card">
                    <h2 class="card-title">{{ group.cardName }}</h2>
                    <!-- Display the card name -->
                    <div v-for="(setting, settingIndex) in filteredElements(group)" :key="settingIndex" class="setting">
                        <div v-if="setting.type === 'dropdown' && !setting.hidden">
                            <label :for="setting.name">{{ translateAndCapitalize(setting.label) }}</label>
                            <select :id="`setting-${group.cardPosition}-${setting.elementIndex}`"
                                v-model="setting.current" @change="handlePlaySoundChange(setting.name, setting.current)"
                                class="styled-input">
                                <option v-for="option in setting.options" :value="option.value" :key="option.value">
                                    {{ translateAndCapitalize(option.label) }}
                                </option>
                            </select>
                        </div>
                        <div v-else-if="setting.type === 'text' && !setting.hidden">
                            <label :for="setting.name">{{ translateAndCapitalize(setting.label) }}</label>
                            <input :id="`setting-${group.cardPosition}-${setting.elementIndex}`" type="text"
                                v-model="setting.current" class="styled-input" maxlength="10" />
                        </div>
                    </div>
                </div>
            </div>
            <button class="button" :disabled="isSaving" @click="saveSettings">
                {{ isSaving ? $t('savingButton') : $t('createAppButton') }}
            </button>
        </template>
        <template v-else>
            <h1>{{ $t('appCreatedTitle') }}</h1>
            <p>{{ $t('appCreatedDescription') }}</p>
            <button class="button" @click="goHome">{{ $t('homeButton') }}</button>
        </template>
    </div>
</template>









<script>
export default {
    data() {
        return {
            isSaving: false,
            saveSuccess: false,
            settings: [], // Array to store settings derived from card elements
            appName: '', // Store the name of the selected app
        };
    },
    created() {
        this.initializeSettings(); // Initialize settings for the card
        const soundSettingsGroup = this.settings.find(group => group.cardName === 'Sound Settings');
        if (soundSettingsGroup) {
            const playSoundSetting = soundSettingsGroup.elements.find(setting => setting.name === 'playSoundEvery');
            if (playSoundSetting) {
                this.handlePlaySoundChange(playSoundSetting.name, playSoundSetting.current);
            }
        }
    },
    methods: {
        handlePlaySoundChange(settingName, currentValue) {
            if (settingName === 'playSoundEvery') {
                const soundSettingsGroup = this.settings.find(group => group.cardName === 'Sound Settings');
                if (soundSettingsGroup && soundSettingsGroup.elements) {
                    soundSettingsGroup.elements.forEach(setting => {
                        if (['soundFromHour', 'soundToHour', 'soundType', 'soundVolume'].includes(setting.name)) {
                            setting.hidden = currentValue === '-1';
                        }
                    });
                }
            }
        },

        filteredElements(group) {
            return group.elements.filter(setting => {
                // Filter out hidden settings
                return !setting.hidden;
            });
        },
        translate(key) {
            // Attempt to get a translation or use the key itself as a fallback
            let translation = this.$t(key);

            // Check if the translation found is the same as the key, indicating no translation exists
            if (translation === key) {
                // If no translation is found, use the key as the default
                translation = key;
            }

            // Make the entire string lowercase then capitalize the first letter of each word
            return translation
        },
        translateAndCapitalize(key) {
            // Attempt to get a translation or use the key itself as a fallback
            let translation = this.$t(key);

            // Check if the translation found is the same as the key, indicating no translation exists
            if (translation === key) {
                // If no translation is found, use the key as the default
                translation = key;
            }

            // Make the entire string lowercase then capitalize the first letter of each word
            return translation
                .toLowerCase()
                .replace(/(?:^|\s|-)\S/g, a => a.toUpperCase()); // Capitalize first letters
        },
        initializeSettings() {
            const selectedCards = this.$store.getters.getSelectedCardPositions;

            if (!selectedCards || selectedCards.length === 0) {
                console.error('No cards found');
                return;
            }

            const settingsDefinitions = [];

            // Create a group for the update interval setting
            const updateIntervalGroup = {
                cardName: 'General Settings',
                elements: [
                    {
                        type: 'text',
                        name: 'appName',
                        label: 'App Name',
                        current: this.appName || "",
                    },
                    {
                        type: 'dropdown',
                        name: 'updateInterval',
                        label: 'Update Interval',
                        current: '30',
                        options: [
                            { label: '5 minutes', value: '5' },
                            { label: '10 minutes', value: '10' },
                            { label: '15 minutes', value: '15' },
                            { label: '30 minutes', value: '30' },
                            { label: '60 minutes', value: '60' },
                        ],
                    }
                ]
            };

            // Add the update interval setting at the top
            settingsDefinitions.push(updateIntervalGroup);

            // Add the sound settings card
            const soundSettingsGroup = {
                cardName: 'Sound Settings',
                elements: [
                    {
                        type: 'dropdown',
                        name: 'playSoundEvery',
                        label: 'Play Sound Every',
                        current: '-1',
                        options: [
                            { label: 'Never', value: '-1' },
                            { label: '15 minutes', value: '15' },
                            { label: '30 minutes', value: '30' },
                            { label: '1 hour', value: '60' }
                        ]
                    },
                    {
                        type: 'dropdown',
                        name: 'soundFromHour',
                        label: 'From Hour',
                        current: '0',
                        options: Array.from({ length: 25 }, (_, i) => ({ label: i.toString(), value: i.toString() }))
                    },
                    {
                        type: 'dropdown',
                        name: 'soundToHour',
                        label: 'To Hour',
                        current: '23',
                        options: Array.from({ length: 25 }, (_, i) => ({ label: i.toString(), value: i.toString() }))
                    },
                    {
                        type: 'dropdown',
                        name: 'soundType',
                        label: 'Sound',
                        current: 'churchbell',
                        options: [
                            { label: 'churchbell', value: 'churchbell' },
                            { label: 'bowl', value: 'bowl' },
                            { label: 'cuckoo', value: 'cuckoo' },
                            { label: 'rooster', value: 'rooster' },
                            { label: 'futuristic', value: 'futuristic' }
                        ]
                    },
                    {
                        type: 'dropdown',
                        name: 'soundVolume',
                        label: 'Volume',
                        current: '50',
                        options: [
                            { label: '10%', value: '10' },
                            { label: '20%', value: '20' },
                            { label: '30%', value: '30' },
                            { label: '40%', value: '40' },
                            { label: '50%', value: '50' },
                            { label: '60%', value: '60' },
                            { label: '70%', value: '70' },
                            { label: '80%', value: '80' },
                            { label: '90%', value: '90' },
                            { label: '100%', value: '100' },
                        ]
                    }
                ]
            };

            // Add the sound settings group to the settings definitions
            settingsDefinitions.push(soundSettingsGroup);

            // Iterate through each selected card
            selectedCards.forEach((cardItem) => {
                const card = cardItem.card;
                const position = cardItem.position;

                // Create a group for each card
                const cardSettings = {
                    cardName: card.name,
                    position: position,
                    elements: []
                };

                const uniqueSettings = new Map();

                // Iterate through each element in the card to define settings
                card.elements.forEach((element, elementIndex) => {
                    if ((element.type === 'text' || element.type === 'icon' || element.type === 'image') && element.selectedVariableName && element.availableVariables) {
                        const key = `${element.selectedVariableName}-${JSON.stringify(element.availableVariables)}`;
                        // console.log(key)
                        if (!uniqueSettings.has(key)) {
                            uniqueSettings.set(key, {
                                type: 'dropdown',
                                name: element.selectedVariableName,
                                label: element.selectedVariableName,
                                current: element.selectedVariable,
                                options: element.availableVariables.map(variable => ({
                                    label: variable,
                                    value: variable
                                })),
                                cardPosition: position,
                                elementIndexes: [elementIndex] // Track all element indexes with the same setting
                            });
                        } else {
                            // If the setting already exists, add the element index to the existing entry
                            uniqueSettings.get(key).elementIndexes.push(elementIndex);
                        }
                    }
                });

                // Push unique settings to the elements array
                cardSettings.elements = Array.from(uniqueSettings.values());

                // Only add the group if there are settings in it
                if (cardSettings.elements.length > 0) {
                    settingsDefinitions.push(cardSettings);
                }
            });

            this.settings = settingsDefinitions;
            // console.log(this.settings);
        },




        goBack() {
            this.$router.go(-1);
        },
        async connect() {
            // The connect method you provided
            const clockName = this.$store.state.authUser.name;
            this.isLoading = true;
            // console.log("Connecting to Cosmo Clock");
            try {
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/login?identifier=${clockName}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to connect. Please try again.');
                }
                const data = await response.json();
                // console.log("Connected successfully");
                this.connectedCosmo = data; // Store the received Cosmo document
                this.$store.dispatch('authenticateUser', data);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading = false; // Reset loading state
            }
        },
        async saveSettings() {
            this.isSaving = true;

            try {
                const selectedCards = this.$store.getters.getSelectedCardPositions;

                if (!selectedCards || selectedCards.length === 0) {
                    throw new Error('No cards found to save');
                }

                // Extract the general settings (like update interval and app name)
                let appName = this.appName;
                let updateInterval = '5'; // Default value
                let soundSettings = {};
                const generalSettingsGroup = this.settings.find(group => group.cardName === 'General Settings');
                const soundSettingsGroup = this.settings.find(group => group.cardName === 'Sound Settings');

                if (generalSettingsGroup && generalSettingsGroup.elements) {
                    generalSettingsGroup.elements.forEach(setting => {
                        if (setting.name === 'appName') {
                            appName = setting.current;
                        } else if (setting.name === 'updateInterval') {
                            updateInterval = setting.current;
                        }
                        // console.log(`General Setting - ${setting.label}: ${setting.current}`);
                    });
                }

                if (soundSettingsGroup && soundSettingsGroup.elements) {
                    soundSettingsGroup.elements.forEach(setting => {
                        soundSettings[setting.name] = setting.current;
                    });
                }

                // Check if an app with the same name already exists
                const existingApp = appName.length === 0 || this.$store.state.authUser.apps.find(app => app.name.toLowerCase() === appName.toLowerCase());

                if (existingApp) {
                    // Display an alert and stop the saving process
                    alert(this.$t('duplicateNameMessage'));
                    this.isSaving = false;
                    return;
                }

                // Prepare the cards with the current settings
                const updatedCards = selectedCards.map(cardItem => {
                    const card = cardItem.card;
                    const position = cardItem.position;
                    // console.log("position", position);

                    // Find the relevant settings for this card
                    const cardSettings = this.settings.find(group => group.cardName === card.name && group.position === position);
                    // console.log("cardSettings", cardSettings);

                    if (cardSettings && cardSettings.elements) {
                        // Create a new array for elements to avoid directly mutating the original array
                        const updatedElements = card.elements.map((element, elementIndex) => {
                            const setting = cardSettings.elements.find(s =>
                                s.name === element.selectedVariableName &&
                                s.cardPosition === position  // Ensure the setting is from the correct card position
                            );

                            if (setting) {
                                // console.log("FOUND SELECTED");
                                // console.log(setting.current);
                                // console.log(setting.elementIndexes);

                                // If the setting exists, update the variable for all related elements
                                if (setting.elementIndexes.includes(elementIndex)) {
                                    return {
                                        ...element,
                                        selectedVariable: setting.current
                                    };
                                }
                            }

                            return element;  // Return the element unchanged if no matching setting is found
                        });

                        // Return the updated card with the new elements array
                        return {
                            ...card,
                            elements: updatedElements,
                            position
                        };
                    }

                    // Return the card unchanged if no relevant settings were found
                    return {
                        ...card,
                        position
                    };
                });

                // console.log('Updated cards:', updatedCards);

                // Construct the object to be sent in the API request
                const customAppData = {
                    name: appName, // Use the appName from the input field
                    updateInterval: updateInterval,
                    soundSettings: soundSettings,
                    cards: updatedCards,
                    clockId: this.$store.state.authUser.clockId, // Assuming clockId is stored in Vuex
                };

                // console.log('Custom App Data:', customAppData);

                //Make the API request to createCustomApp
                const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/createCustomApp`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(customAppData),
                });

                if (!response.ok) {
                    throw new Error('Failed to create custom app. Please try again.');
                }

                await response.json();
                // console.log('Custom app created successfully:', responseData);

                await this.connect();

                this.saveSuccess = true;
            } catch (error) {
                console.error('Error saving settings:', error);
                alert(error.message); // Show error message
            } finally {
                this.isSaving = false;
            }
        },
        goHome() {
            this.$router.push({ name: 'CosmoHome' });
        },
    },
};
</script>

<style scoped>
.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.settings-wrapper {
    margin: 30px 0;
    /* Adjust the top and bottom margin as needed */
    width: 100%;
    /* Ensure it spans the width of its container */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center-align the settings */
}

.settings-card {
    background-color: #333;
    padding: 20px;
    border-radius: 20px;
    width: 90%;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.setting {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.setting label {
    flex-shrink: 0;
    /* Prevent the label from shrinking */
    margin-right: 10px;
    /* Space between the label and the input */
    white-space: nowrap;
    /* Prevent label from wrapping onto multiple lines */
}

.toggle-wrapper {
    transform: scale(0.8);
    display: inline-block;
}

/* This targets the direct container of the toggle and label, adjust as needed */
.setting>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Creates space between the label and the toggle */
}

.styled-input {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 50%;
    /* Ensure full width */
    -webkit-appearance: none;
    appearance: none;
}

.styled-input option {
    background-color: #262626;
    color: white;
}


.question-mark {
    cursor: pointer;
    color: rgb(100, 100, 100);
    /* Ensure the question mark is white */
    margin-left: 5px;
    font-size: 16px;
    /* Adjust the size of the question mark */
    border: 1px solid rgb(104, 104, 104);
    /* Create a border around the question mark */
    border-radius: 50%;
    /* Make the border rounded */
    padding: 2px;
    /* Add some padding around the question mark */
    display: inline-flex;
    /* Use flex to center the content */
    align-items: center;
    /* Align items vertically */
    justify-content: center;
    /* Center content horizontally */
    text-align: center;
    /* Ensure text is centered */
}

.vue-toggle {
    transform: scale(0.1);
    /* Adjust scale as needed to make the toggle smaller */
}

.setting select {
    padding: 5px 10px;
    background-color: transparent;
    /* No background */
    color: white;
    /* Text color */
    border: 1px solid white;
    /* White border */
    border-radius: 5px;
    /* Rounded corners */
    cursor: pointer;
    /* Pointer cursor on hover */
    font-size: 16px;
    /* Text size */
    -webkit-appearance: none;
    /* For some browsers to remove default styling */
    appearance: none;
}

.setting option {
    background-color: #262626;
    /* No background */
    color: white;
    /* Text color */
}

.button {
    margin-top: 10px;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.detail-button {
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    /* Creates space between the label and the button */
    transition: background-color 0.3s ease;
}

.detail-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}


.container p {
    margin-bottom: 0px;
}
</style>
